<template>
<div>
    
    <slot v-if="!content">
        <div class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>  
    <NewsContent v-if="content" :content="content"></NewsContent>
    <div v-if="content" style="padding: 10px 7px;">
        <NewsRow :cat="content.category_id" :count="10" :title="content.category_title" :type="'id'" />
    </div>
    <NewsComments v-if="content"></NewsComments>
</div>
</template>

<script>
import NewsContent from '@/components/Mobile/News/NewsContent.vue'
import NewsComments from '@/views/TgjuPostsMobile.vue'
import NewsRow from '@/components/Inc/newsRow.vue'

export default {
    name: 'ViewApplicationNewsContent',
    components: {
        NewsContent,
        NewsComments,
        NewsRow
    },
    data: function () {
        return {
            content : null,
        }
    },
    mounted() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.$helpers
                .makeRequest("GET", `/news/content/`, { id : this.$route.params.id})
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.content = api_response.data.response.detail.response.item;
                    }
                });
        },
    }
}
</script>
